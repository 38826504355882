import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { NavController } from '@ionic/angular';
import { SendOtpPasswordResponse } from '@models/auth.model';
import { StorageKey } from '@models/storage-key.model';
import { UserInfoService } from '@services/user-info/user-info.service';
import { Observable, tap } from 'rxjs';
import { APP_ROUTES } from 'src/app/routes';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly http: HttpClient,
    private readonly navCtrl: NavController,
    private readonly userInfoService: UserInfoService
  ) {}

  get accessToken() {
    return localStorage.getItem(StorageKey.ATO) || '';
  }

  set accessToken(token: string) {
    localStorage.setItem(StorageKey.ATO, token);
  }

  public loginByPhone(phone: string) {
    return this.http.post(`${environment.publicUrl}/sendLoginPassword`, {
      phone,
    });
  }

  public sendOtpPassword(phone: string, otp: string): Observable<SendOtpPasswordResponse> {
    return this.http
      .post<SendOtpPasswordResponse>(`${environment.publicUrl}/sendOtpPassword`, {
        phone,
        otp,
        _platForm: '',
      })
      .pipe(
        tap(resp => {
          this.accessToken = resp.access_token;
          this.userInfoService.fetchCurrentUserInfo();
        })
      );
  }

  logout(): void {
    localStorage.clear();
    this.navCtrl.navigateRoot(APP_ROUTES.ONBOARDING);
  }
}
