import { inject } from '@angular/core';
import { CanActivateFn, GuardResult, MaybeAsync } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';

export const AuthGuard: CanActivateFn = (): MaybeAsync<GuardResult> => {
  const authService = inject(AuthService);

  if (!authService.accessToken) {
    authService.logout();
    return false;
  }

  return true;
};
