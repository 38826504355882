import { Route } from '@angular/router';
import { AuthGuard } from './shared/guards/auth/auth.guard';

export enum APP_ROUTES {
  REVIEWS = 'reviews',
  REVIEWS_ADMIN = 'reviews-admin',
  ONBOARDING = 'onboarding',
  LOGIN = 'login',
  HOME = 'home',
  WEB_APP = 'web-app',
  PRE_REGISTRATION = 'pre-registration',
  REGISTRATION = 'registration',
  PAYMENT = 'payments',
  REFERRALS = 'referrals',
  NOTIFICATIONS = 'notifications',
  SUPPORT = 'support',
}

export const appRoutes = [
  { path: '', redirectTo: APP_ROUTES.HOME, pathMatch: 'full' },
  {
    path: APP_ROUTES.PRE_REGISTRATION,
    loadChildren: () => import('./features/pre-registration/pre-registration.routes').then(m => m.PreRegistrationRoutes),
  },
  {
    path: APP_ROUTES.ONBOARDING,
    loadChildren: () => import('./features/onboarding/onboarding.routes').then(m => m.OnboardingRoutes),
  },
  { path: APP_ROUTES.LOGIN, loadComponent: () => import('./features/login/login.component') },
  { path: APP_ROUTES.WEB_APP, loadChildren: () => import('./features/web-app/web-app.routes') },
  { path: APP_ROUTES.REGISTRATION, loadChildren: () => import('./features/registration/registration.routes') },
  {
    path: '',
    canActivate: [AuthGuard],
    data: { showTabs: true },
    children: [
      {
        path: APP_ROUTES.HOME,
        loadComponent: () => import('./features/home/home.component'),
      },
      {
        path: APP_ROUTES.REVIEWS,
        loadChildren: () => import('./features/reviews/reviews.routes'),
      },
      {
        path: APP_ROUTES.PAYMENT,
        loadChildren: () => import('./features/payments/payments.routes'),
      },
      {
        path: APP_ROUTES.REVIEWS_ADMIN,
        loadComponent: () => import('./features/reviews-admin/reviews-admin.page'),
      },
      {
        path: APP_ROUTES.REFERRALS,
        loadChildren: () => import('./features/referrals/referrals.routes'),
      },
      {
        path: APP_ROUTES.NOTIFICATIONS,
        loadComponent: () => import('./features/notifications/pages/notifications.page'),
      },
      {
        path: APP_ROUTES.SUPPORT,
        loadChildren: () => import('./features/support/support.routes'),
      },
    ],
  },
  { path: '**', redirectTo: APP_ROUTES.HOME, pathMatch: 'full' },
] satisfies Route[];
